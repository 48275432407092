<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    content-class="b-dialog-question"
    :max-width="320"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal(false)"
  >
    <v-form
      @submit.prevent="execute"
      ref="form"
      lazy-validation
      v-model="valid"
    >
      <v-card class="b-dialog-question-card">
        <div class="b-dialog-question-card--header">
          <v-icon color="primary" size="40">fa-solid fa-retweet</v-icon>
        </div>
        <div class="b-dialog-question-card--body">
          <h3>Para quem você deseja demandar o chamado ?</h3>
          <v-autocomplete
            id="businessUnitId"
            name="businessUnitId"
            label="Unidade de negócio"
            item-text="name"
            item-value="id"
            outlined
            dense
            required
            :rules="[required]"
            :items="businessUnits"
            v-model="model.businessUnitId"
            @change="onChangeBusinessUnit"
          />
          <v-autocomplete
            id="demandedUserId"
            name="demandedUserId"
            label="Responsável"
            item-text="name"
            item-value="id"
            outlined
            dense
            required
            :rules="[required]"
            :items="demandedResponsible"
            v-model="model.demandedUserId"
            v-if="model.businessUnitId"
          />
          <v-textarea
            id="message"
            name="message"
            label="Digite o resumo do chamado"
            outlined
            dense
            required
            :rules="[required]"
            v-model="model.message"
            v-if="modeEdit"
          />
          <v-file-input
            id="files"
            name="files"
            label="Adicionar arquivos"
            dense
            outlined
            multiple
            v-model="model.files"
            v-if="modeEdit"
          />
        </div>
        <div class="b-dialog-question-card--footer">
          <BaseButton
            id="btn-back"
            name="btn-back"
            outlined
            color="primary"
            title="Voltar"
            @click="closeModal(false)"
          />
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            title="Demandar"
            :disabled="!valid"
          />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { SupportService } from '@/services/api/support';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    ticketId: {
      type: String,
      required: true
    },
    modeEdit: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    businessUnits: [],
    demandedResponsible: [],
    valid: true,
    model: {
      businessUnitId: null,
      demandedUserId: null,
      message: null,
      files: []
    }
  }),

  created() {
    this.getAvailableBusinessUnits();
  },

  methods: {
    closeModal(action) {
      this.$emit('closeModal', action);
    },

    async getAvailableBusinessUnits() {
      try {
        const supportService = new SupportService();
        const { status, data } =
          await supportService.getAvailableBusinessUnits();

        if (status === 200) {
          this.businessUnits = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async onChangeBusinessUnit() {
      try {
        this.model.demandedUserId = null;

        const supportService = new SupportService();
        const { status, data } = await supportService.getDemandedResponsible(
          this.model.businessUnitId
        );

        if (status === 200) {
          this.demandedResponsible = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    execute() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      if (this.modeEdit) {
        this.demandUser();
      } else {
        this.changeTicketResponsible();
      }
    },

    async demandUser() {
      try {
        const supportService = new SupportService();
        const { status } = await supportService.postDemandUser({
          ticketId: this.ticketId,
          ...this.model
        });

        if (status === 204) {
          showMessage('success', 'Chamado demandado com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async changeTicketResponsible() {
      try {
        const supportService = new SupportService();
        const { status } = await supportService.putChangeTicketResponsible({
          ticketId: this.ticketId,
          ...this.model
        });

        if (status === 204) {
          showMessage('success', 'Chamado demandado com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
